import React from 'react'; 

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container'; 
import Typography from '@material-ui/core/Typography'; 
import Box from '@material-ui/core/Box'; 
import CssBaseline from '@material-ui/core/CssBaseline'; 

import Meta from 'components/Meta';
import Link from 'components/Link';

import Copyright from 'widgets/Copyright';
import Header from 'widgets/Header';
import Footer from 'widgets/Footer';  

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1, 
  }, 
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  faq: {
    marginTop: theme.spacing(3),
  }
})); 

export default function About() { 
  const classes = useStyles(); 

  return ( 
    <React.Fragment>
      <CssBaseline />
      <Meta title="404 Page Not Found" description="" slug="404" /> 
      <Header/> 
      <Container className="content" maxWidth="md">  
        <div className={classes.paper}>
          <Typography component="h2" variant="h1">
            404
          </Typography> 
          Opps! Page not found
        </div>
      </Container>
      <Footer/>  
    </React.Fragment>
  );
}